<template>
    <div>
        <span class="date">
            <span>{{ date.year }}年</span>
            <template v-if="dateType === 'month'">
                <span>{{ date.month }}月</span>
            </template>
            <template v-else-if="dateType === 'date'">
                <span>{{ date.month }}月</span>
                <span>{{ date.date }}日</span>
            </template>
        </span>
        <a @click="add(-1)" :class="buttonStyle">＜</a>
        <a @click="add(0)" :class="buttonStyle">{{ text[dateType] }}</a>
        <a @click="add(1)" :class="buttonStyle">＞</a>
    </div>
</template>

<script>
    "use strict";

    export default {
        "props": {
            "dateType": {
                "default": "month",
                "required": false,
                "type": String,
            },
            "selectMonth": {
                "default": "",
                "required": false,
                "type": String,
            },
            "selectYear": {
                "default": "",
                "required": false,
                "type": String,
            },
        },
        data() {
            return {
                "buttonStyle": "button--other",
                "date": {
                    "date": null,
                    "month": null,
                    "year": null,
                },
                "text": {
                    "date": "今日",
                    "month": "今月",
                    "year": "今年",
                },
            };
        },
        created() {
            const today = new Date();
            let year = today.getFullYear();
            if (this.selectYear) {
                year = this.selectYear;
            }
            let month = ("0" + (today.getMonth() + 1)).slice(-2);
            if (this.selectMonth) {
                month = Number(this.selectMonth);
            }
            const date = ("0" + today.getDate()).slice(-2);
            const dateFirst = new Date(year, today.getMonth(), 1);
            const dateLast = new Date(year, month, 0);
            let dateVal = "";
            let dateFirstVal = "";
            let dateLastVal = "";
            if (this.dateType === "year") {
                dateVal = year;
            } else if (this.dateType === "month") {
                dateVal = `${year}${"-"}${month}`;
                dateFirstVal = `${year}${"-"}${month}${"-"}${"0"}${dateFirst.getDate()}`;
                dateLastVal = `${year}${"-"}${month}${"-"}${dateLast.getDate()}`;
            } else if (this.dateType === "date") {
                dateVal = `${year}${"-"}${month}${"-"}${date}`;
            }
            this.date.year = year;
            this.date.month = month;
            this.date.date = today.getDate();
            this.$emit("select-date", dateVal, dateFirstVal, dateLastVal);
        },
        "methods": {
            add(val) {
                let currentTime;
                let currentTimeFirst;
                let currentTimeLast;
                let currentTimeData = `${this.date.year}${"-"}${this.date.month}${"-"}${this.date.date}`;
                let currentTimeFirstData;
                let currentTimeLastData;
                if (val === 0) {
                    currentTime = new Date();
                } else {
                    currentTime = new Date(currentTimeData);
                }
                if (this.dateType === "year") {
                    currentTime.setFullYear(currentTime.getFullYear() + val);
                    currentTimeData = currentTime.getFullYear();
                } else if (this.dateType === "month") {
                    currentTime.setMonth(currentTime.getMonth() + val);
                    currentTimeFirst = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
                    currentTimeLast = new Date(currentTime.getFullYear(), currentTime.getMonth() + 1, 0);
                    currentTimeData = `${currentTime.getFullYear()}${"-"}${("0" + (currentTime.getMonth() + 1)).slice(-2)}`;
                    currentTimeFirstData = `${currentTimeFirst.getFullYear()}${"-"}${("0" + (currentTimeFirst.getMonth() + 1)).slice(-2)}${"-"}${("0" + currentTimeFirst.getDate())}`;
                    currentTimeLastData = `${currentTimeLast.getFullYear()}${"-"}${("0" + (currentTimeLast.getMonth() + 1)).slice(-2)}${"-"}${currentTimeLast.getDate()}`;
                } else if (this.dateType === "date") {
                    currentTime.setDate(currentTime.getDate() + val);
                    currentTimeData = `${currentTime.getFullYear()}${"-"}${("0" + (currentTime.getMonth() + 1)).slice(-2)}${"-"}${("0" + currentTime.getDate()).slice(-2)}`;
                }
                this.date.year = currentTime.getFullYear();
                this.date.month = currentTime.getMonth() + 1;
                this.date.date = currentTime.getDate();
                this.$emit("select-date", currentTimeData, currentTimeFirstData, currentTimeLastData);
            },
        },
    };
</script>
