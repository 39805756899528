<template>
    <div class="dashboard cust-page-mypage">
        <h1>マイページ</h1>
        <select v-model="params.team_id" @change="historyPush()" :disabled="flagDisabled">
            <option value="">選択してください。</option>
            <option v-for="(data, index) in sortingListTeams" :key="`option${index}`" :value="data.team_id">{{ data.team_name }}</option>
        </select>
        <div class="l-mt0 l-mb4 cust-page-mypage__select">
            <select v-model="params.data_select" @change="historyPush()" class="l-mt2 l-mr3">
                <option v-for="(text, key) in listCategory" :key="key" :value="key">{{ text }}</option>
            </select>
            <SelectDate v-if="params.data_select === 'create'" class="l-mt2" :date-type="dateType" :select-month="selectMonth" :select-year="selectYear" @select-date="handleSelectDate" />
        </div>
        <table class="cust-table">
            <tr>
                <th v-for="data in tableSet" :key="data.key">
                    {{ data.head }}
                </th>
            </tr>
            <router-link v-for="(list, index) in sortingListSupportDetails" :key="`list${index}`" :to="{ name: 'support_data', params: { id: String(list.id), creator: String(list.team_name) }}" tag="tr">
                <td v-for="data in tableSet" :key="`list${index}${data.key}`" :class="data.class" v-html="list[data.key]"></td>
            </router-link>
        </table>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import SelectDate from "@/components/SelectDate.vue";
    import apiListTeams from "@/mixins/apiListTeams";
    import common from "@/mixins/common";
    import sorts from "@/mixins/sort";

    const tableSearch = [
        {
            "class": "",
            "head": "チーム名",
            "key": "team_name",
        },
        {
            "class": "",
            "head": "支援終了日",
            "key": "support_end_date",
        },
        {
            "class": "is-wrap cust-table__link",
            "head": "ケースのタイトル",
            "key": "case_title",
        },
    ];

    export default {
        "components": {
            SelectDate,
        },
        "mixins": [
            apiListTeams,
            common,
            sorts,
        ],
        data() {
            return {
                "dateType": "month",
                "flagDisabled": true,
                "listCategory": {
                    "create": "作成したデータ",
                    "like": "いいね！したデータ",
                    "liked": "いいね！されたデータ",
                    "comment": "コメントしたデータ",
                    "commented": "コメントされたデータ",
                },
                "listSupportDetails": [],
                "params": {
                    "team_id": localStorage.getItem("teamId"),
                    "data_select": "create",
                    "start_date": "",
                    "end_date": "",
                },
                "role": localStorage.getItem("role"),
                "selectMonth": "",
                "selectYear": "",
                "tableSet": tableSearch,
            };
        },
        "computed": {
            sortingListSupportDetails() {
                // 支援終了日で降順
                const list = this.listSupportDetails;
                const key = "support_end_date";
                return this.$_sorting(list, key);
            },
            sortingListTeams() {
                // チームIDで昇順
                const list = this.listTeams;
                // const key = "team_id";
                // return this.$_sorting(list, key);
                return list;
            },
        },
        created() {
            this.$_fetchingListTeams();
            if (this.role === "0") {
                this.flagDisabled = false;
                this.params.team_id = "";
            }
            this.fetchingListSupportDetails();
        },
        "methods": {
            getParams() {
                if (this.$route.query.data_select) {
                    for (let key in this.params) {
                        this.params[key] = this.$route.query[key];
                    }
                    if (this.params.start_date) {
                        const dateArray = this.params.start_date.split("-");
                        this.selectYear = dateArray[0];
                        this.selectMonth = dateArray[1];
                    }
                }
            },
            historyPush() {
                // ブラウザ履歴に登録
                this.$router.push({ "name": "my_page", "query": this.params }, () => {}, () => {});
                this.fetchingListSupportDetails();
            },
            handleSelectDate(dateVal, dateFirstVal, dateLastVal) {
                this.params.start_date = dateFirstVal;
                this.params.end_date = dateLastVal;
                this.historyPush();
            },
            fetchingListSupportDetails() {
                // クエリからパラメータを取得
                this.getParams();
                // マイ支援記録データ取得
                if (this.params.data_select === "create" && (!this.params.start_date || !this.params.end_date)) {
                    return false;
                }
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/support_data/my_data`,
                    this.$_requestConfig("params")
                ).then(response => {
                    this.listSupportDetails = response.data;
                }).catch(() => {
                    this.$_modalError("supportDetailsSearchError");
                });
            },
        },
    };
</script>
